
export const ProductLiabilityInsuranceData = [
    {
        componentType: "title",
        content: "Product",
        spantext: " liability insurance"
      },
      {
        componentType: "paragraph",
        content: `Manufacturers or traders of products can suffer financial losses apart from damage to
        reputation if such products affect the consumers of such products. Product liability
        insurance protects manufacturers and traders of products from financial losses arising out
        of effects of such products. The coverage includes not only the financial loss which the
        manufacturers and traders become liable to pay but also includes legal costs incurred to
        defend themselves before law.`
      },
      
]