
export const EmployeesCompensationInsuranceData = [
    {
        componentType: "title",
        content: " Workmen",
        spantext: " Compensation insurance"
      },
      {
        componentType: "paragraph",
        content: `Employers are statutorily liable to compensate their employees in respect of workplace related injuries sustained by the employees.  The statute that deals with such liabilities to compensate is Employees Compensation Act,1923 (referred hitherto as “ EC Act”). This statute was previously called as Workmen’s Compensation Act, 1923.`
      },
      {
        componentType: "title",
        content: "The EC ",
        spantext: "Act"
      },
      {
        componentType: "paragraph",
        content:`Under the EC Act, if the employee suffers any injuries or death arising out of and in the course of employment, the employer is liable to pay compensation to the employee or the legal heirs of the employee. The EC Act defines who is an employee. The phrase “arising out of and in the course of employment” is the central to this statute. Whether the given injury or death arose out of or in the course of employment is one of the most contested issues under this statute and hence very often becomes a subject matter of interpretation by the courts.
        The EC Act discusses in detail about compensation payable and gives a structure for calculating the compensation.`
      },
      {
        componentType: "paragraph",
        content:`The EC Act also gives a table giving percentage of disability for various injuries. Hence it is important for any employer to understand the provisions and implications of the Act so that they can either avoid a legal liability or discharge the legal liability to the satisfaction of the Act.`
      },
      {
        componentType: "paragraph",
        content:`This Act does not mandate an employer to cover this statutory liability through insurance. It is the employer who has to decide whether to opt for Workmen Compensation Insurance or not.`
      },
      {
        componentType: "title",
        content: " Coverage under Employee’s Compensation Insurance / Workmen’s",
        spantext: " Compensation Insurance"
      },
      {
        componentType: "paragraph",
        content: " Workmen Compensation’s Insurance  covers the legal liability of the insured employer towards their employees under the Employee Compensation Act, 1923.",
      },
      {
        componentType: "paragraph",
        content: "The coverage can be extended to include the liabilities under Fatal Accident Act, 1855 and liability under common law. Compensation under the said EC Act is calculated based on two factors namely employee’s salary and age. It does not take into consideration loss to the estate, loss of love and affection, compensation for pain and suffering etc. Hence the compensation received can be less when compared to compensation under Fatal accidents Act or under common law. If the affected employee seeks compensation under more “heads”, then the employer may become liable to pay more compensation. SO, opting to coverage the liability under Fatal Accidents Act, 1855 and common law is advisable.",
      },
      {
        componentType: "paragraph",
        content: "Exclusions under the",
        spantext: "policy includes"
      },
      {
        componentType: "list",
        content: [
          `War and nuclear risks`,
          `Liability to employees of the contractor of insured`,
          `Towing of disabled vehicles`,
          `Contractual liability`,
          `Any liability towards a person who is not an employee within the meaning of the EC Act.`,
        ]
      },
      {
        componentType: "paragraph",
        content: " The above details present a broad picture of liability under the EC Act and coverage under Workmen’s Compensation Insurance.  Customer who intends to purchase this cover should examine the provisions of the EC Act and complete terms and conditions of the insurance cover to understand the implications of the EC Act and the policy terms and conditions. ",
      },
      
]