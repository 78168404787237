
export const CyberCrimeInsuranceData = [
    {
        componentType: "title",
        content: "Cyber",
        spantext: " insurance"
      },
      {
        componentType: "paragraph",
        content: `Cyber insurance is sometimes referred as cyber liability insurance or as cyber crime
        insurance. This is an evolving insurance product because cyber-crimes are fast evolving with
        the new technological breakthroughs. From internet to smartphones to cloud storage to
        artificial intelligence, cyber crimes have evolved and kept pace. We are seeing public
        suffering a lot of financial loss due to cyber-crimes. Since there are multiple cyber crimes
        like data theft, identity theft, email spoofing, ransomware, breach of privacy, cyber bullying,
        cyber extortion, online sale fraud, online matrimonial fraud, medial liability etc., cyber
        insurance products can be highly customized to include or exclude such crimes.`
      },
      {
        componentType: "paragraph",
        content: `To understand the nature of insurance coverages it is very essential to understand the
        nature and impact of these cybercrimes. Insurance policies broadly explain such crimes
        covered by the policy. It is very essential for the insuring public to understand these crimes
        and choose the covers available.`
      },
      {
        componentType: "paragraph",
        content: `This policy covers both first party loss and third-party loss. First party loss means the
        insured directly suffers financial loss. The classic example is the recent “Fedex” scam or
        “OTP” frauds. Third party loss means the customers of the insured, affected by the cyber-
        crimes on the insured, sues insured for their negligence in protecting their identity &amp; data
        and seeks compensation from insured.`
      },
      
]