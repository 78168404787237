export const EractionAllInsuranceData = [
    
      {
        componentType: "paragraph",
        content: `Contractor All Risk policy is designed to cover the risks in installation or erection of machinery and
        equipment in a factory or office or residential apartments. It primarily covers
        the costs of erection.
        Further the landed cost of materials, imports and indigenous permanent civil works are also included
        in the coverage. incurs many other expenditures which
        may not be included in the project costs.
        Further contractor is exposed to many liabilities that may arise during the execution of the project.
        This policy covers such risks also.`
      },
      {
        componentType: "title",
        content: " Coverage  ",
      },
      {
        componentType: "paragraph",
        content: `The term “All Risks” policy, also called as “Exclusions Driven” policy, means policy covers all the
        perils which are not excluded. Hence in such policies perils are not listed,
        only exclusions are listed.`
      },
      {
        componentType: "title",
        content: " Period   ",
        spantext: " of cover"
      },
      {
        componentType: "paragraph",
        content: `Period of cover commences from the time property insured is unloaded at the site and ceases once
        the test loading or first test operation(whichever is earlier) is concluded.
        However, insurance policy
        will mention the period of insurance as declared in the proposal form. In case the work of erection is
        not completed, based on the request of the
        insured, subject to discretion of insurer and payment of
        additional premium the period of insurance can be extended.`
      },
      {
        componentType: "title",
        content: " Exclusions:  ",
      },
      {
        componentType: "paragraph",
        content: `Exclusions that is applicable to entire policy are`
      },
      {
        componentType: "list",
        content: [
          `War perils`,
          `Nuclear perils`,
          `Willful negligence or willful act of Insured or his authorized representatives`,
          `Cessation of work, whether total or partial`,
        ]
      },
      {
        componentType: "paragraph",
        content: `This policy has following sections`
      },
      {
        componentType: "list",
        content: [
          `Material Damage`,
          `Third Party Liability`,
        ]
      },
      {
        componentType: "title",
        content: " Material damage ",
        spantext: "section"
      },
      {
        componentType: "title",
        content: " Basis of   ",
        spantext: "sum insured"
      },
      {
        componentType: "paragraph",
        content: `Since the cost of erection projects runs into crores of rupees it is very essential for the insured to
        arrive at proper sum insured. The sum insured should represent the completely
        erected value of the
        property and should include freight, customs duty, erection cost.`
      },
      {
        componentType: "paragraph",
        content: `It should be noted that if at the time of loss sum insured is less than the amount required to be
        insured, the amount recoverable for insurer shall be reduced in the proportion
        of sum already
        insured to amount that should have insured.`
      },
      {
        componentType: "title",
        content: " Additional   ",
        spantext: "covers"
      },
      {
        componentType: "paragraph",
        content: `This section also provides additional covers like extra cost for overtime, express freight, additional
        customs duty, cover for construction plant and machinery, surrounding property
        damage, clearance
        &amp; removal of debris, major perils/ AOG perils`
      },
      {
        componentType: "title",
        content: "Testing:  ",
      },
      {
        componentType: "paragraph",
        content: `When erection of a machinery is completed, before commissioning it needs to be tested by applying
        “load” to the machinery to check the performance of the erected machinery.
        The premium charged
        includes one month of testing period. If the testing period is to be extended beyond one month
        additional premium is charged.
        Claims during testing period attracts higher excess.`
      },
      {
        componentType: "title",
        content: "Exclusions:  ",
      },
      {
        componentType: "paragraph",
        content: `This section carries very important exclusions including`
      },
      {
        componentType: "list",
        content: [
          `loss discovered only at the time of taking inventory`,
          `loss due to normal wear and tear, gradual deterioration, breakage of glass`,
          `loss or damage due to faulty design`,
          `cost of replacement, repair or rectification of defective material / workmanship`,
          `the cost necessary for rectification or correction of any error during construction unless
          resulting in physical loss or damage;`,
          `loss of or damage to files, drawings, accounts, bills, currency, stamps, deeds, evidence of
          debt, notes, securities, cheques, packing materials suchas cases, boxes, crates`,
          `any damage or penalties on account of the Insured&#39;s non-fulfillment of the terms of delivery
          under contract`,
        ]
      },
      {
        componentType: "title",
        content: "Third Part   ",
        spantext: "liability"
      },
      {
        componentType: "paragraph",
        content: `This cover will indemnify insured against legal liability for accidental loss or damage caused to
        property of third parties, injury (fatal/non-fatal) to third parties. This section does
        not cover liability
        towards employees.`
      },{
        componentType: "title",
        content: "Note:  ",
      },
      {
        componentType: "paragraph",
        content: `Project contracts may contain many terms and conditions, expressed through a heavily worded
        document. Insured should understand the implications of these terms and
        conditions. Many terms in
        the contract can make insured responsible for certain consequences, so it is advisablefor insured to
        deal with such consequences through
        appropriate insurance.`
      },
      {
        componentType: "paragraph",
        content: `The above details provide a summarized view of the Contractor’s All Risk insurance. Insured should
        analysis entire terms and conditions of the policy to decide whether the terms
        and conditions covers
        the risk he is exposed.`
      },
]