
export const CommercialVehicalInsuranceData = [
    {
        componentType: "title",
        content: " Motor",
        spantext: " vehicle insurance"
      },
      {
        componentType: "paragraph",
        content: ` In spite of you adopting best of the methods to protect your vehicle, accidents and mishaps happen, leading to damage to vehicle and injuries/death to persons. Proper insurance cover can go a long way in dealing with the financial consequences of such accidents. Basically, a motor vehicle insurance deals with damages to car due to various perils and the legal compensation to victims of accidents caused by your vehicle. However, depending on the type of vehicle & use of vehicle the terms of cover vary. Also, coverages addressing various situations are also provided by insurance companies.`
      },
      {
        componentType: "paragraph",
        content:`Insuright Brokers provides you most appropriate advices through understanding your insurance needs and suggest you the best possible coverages available in the market. As long as you are the owner of the vehicle we will be there with you, from the proposal stage, negotiation stage, assisting you in finalizing the best of the coverage; terms and conditions, claims support, assist you in dispute resolution and during the renewal of the policy.`
      },
      {
        componentType: "title",
        content: " Goods Carrying",
        spantext: " Vehicle insurance"
      },
      {
        componentType: "paragraph",
        content:`As the name indicates, commercial vehicles are used for transport purpose, wherein the owner of the vehicle earns revenue through the use of the vehicle. Hence such vehicles are subject to more regulations and such regulation impacts the insurance coverage also.`
      },
      {
        componentType: "paragraph",
        content:`Though there is no  legal definition of what is a commercial vehicle, Motor Vehicles Act, 1988 defines a contract carriage, educational institution bus, goods carriage, heavy goods vehicle, heavy passenger motor vehicle, light motor vehicle, maxi cab, medium goods vehicle, medium passenger carrying vehicle, motor cab, omnibus, public service vehicle, stage carriage, tourist vehicle, transport vehicle. All these types of vehicles are permitted by the statute to be used for hire or reward and regulated accordingly.`
      },
      {
        componentType: "paragraph",
        content:`In the above definitions we see reference to goods vehicle. These vehicles are insured through Commercial Vehicle (Goods Carrying Vehicle) insurance policies.`
      },
      {
        componentType: "paragraph",
        content:`As per Motor Vehicles Act, 1988 no vehicle is permitted to ply in a public place unless a valid insurance policy covers the vehicle. `
      },
      {
        componentType: "paragraph",
        content:`The coverages for a goods carrying vehicle can be broadly classified into 4 sections.`
      },
      {
        componentType: "list",
        content: [
          `Own-damage section`,
          `Third party section`,
          `Towing of disabled vehicles`,
          `Personal Accident cover for the owner cum driver section`,
          `Special covers through Add Ons section`,
        ]
      },
      {
        componentType: "title",
        content: " Own ",
        spantext: " damage section"
      },
      {
        componentType: "paragraph",
        content:`provides cover against the financial loss to insured in repairing / replacing the
        parts damaged due to accidents, fire, explosion lightning, riots, strikes, malicious acts,
        natural causes
        like storm, flood etc., earthquake, terrorism, burglary, theft, landslide, rockslides.
        Total loss of the vehicle due to above causes is also covered.`
      },
      {
        componentType: "paragraph",
        content:`However, this section does not cover depreciation, wear &amp; tear, failures, breakages, consequential
        loss, damages to tyres and tubes(unless vehicle is also
        damaged at the same time, subject to 50%
        depreciation), loss when driving under influence of alcohol, loss when driving with invalid license.`
      },
      {
        componentType: "title",
        content: " Third  ",
        spantext: " Party Section"
      },
      {
        componentType: "paragraph",
        content:`covers the liability of the owner of the vehicle that he/she is legally liable under the Motor Vehicles Act, 1988 for the death or disability to a third-party person due negligent use of the vehicle in a public place. It also the liability of the owner under this Act for the damages to the property belonging to the third party due to negligent use of the vehicle in public place. It also covers the cost of defending the claim for compensation by the third parties.`
      },
      {
        componentType: "title",
        content: " Towing of Disabled",
        spantext: " AccidVehiclesent Section"
      },
      {
        componentType: "paragraph",
        content:`provides cover for the insured vehicle while it is towing any disabled vehicle, provided this towing is done not for hire or reward. This insurance is not extended to cover the towed vehicle.`
      },
      {
        componentType: "title",
        content: " Personal  ",
        spantext: " Accident Section"
      },
      {
        componentType: "paragraph",
        content:`for owner cum driver provides compensation for death or disability to
        owner cum driver of the insured car due to an accident while driving / mounting into /
        dismounting
        from the vehicle.`
      },
      {
        componentType: "paragraph",
        content:`Exclusions applicable to all the sections includes`
      },
      {
        componentType: "list",
        content: [
          `Loss or damages to vehicle / liability caused or created outside the geographical limits.`,
          `Any claim arising out of contractual liability`,
          `Loss or damage or liability caused or created whilst using the vehicle arising out of use of the
          vehicle in violation of the “Limitations as
          to Use” clause of the vehicle.`,
          `Loss or damage or liability caused or created whilst driving vehicle in violation of “Driver’s
          Clause” of the policy.`,
          `Any consequential loss resulting from any loss or damage to the vehicle.`,
          `Any loss or damage caused by nuclear events.`,
          `Any loss or damage due war and warlike operations.`,
        ]
      },
    
]