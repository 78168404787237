import "./App.css";
import MobileInsurance from "./Pages/MobileInsurance";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import PageNotFound from "./components/PageNotFound";
import PrivacyPolicies from "./views/PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "./views/Terms&Conditions/Terms&Conditions";
import BecomePosp from "./Pages/BecomePosp";
import PospLogin from "./Pages/PospLogin";
import ContactUs from "./Pages/ContactUs";
import HealthInsurance from "./Pages/HealthInsurance";
import ClaimAssistance from "./Pages/ClaimAssistance";
import BikeInsurance from "./views/Footer Pages/BikeInsurance/BikeInsurance";
import CarInsurance from "./views/Footer Pages/carInsurance";
import TopupInsurance from "./views/Footer Pages/TopupInsurance";
import TravelInsurance from "./views/Footer Pages/TravelInsurance";
import PersonalAccidentInsurance from "./views/Footer Pages/PersonalAccidentInsurance";
import GroupMediclaim from "./views/Footer Pages/GroupMediclaim";
import FireInsurance from "./views/Footer Pages/FireInsurance";
import MarineInsurance from "./views/Footer Pages/MarineInsurance";
import ContractorInsurance from "./views/Footer Pages/ContractorInsurance";
import ErectionAllInsurance from "./views/Footer Pages/ErectionAllInsurance";
import AboutUs from "./views/AboutUs/AboutUs";
import OurTeam from "./views/OurTeam/OurTeam";
import CommercialVehicalInsurance from "./views/Footer Pages/commercialVehicalInsurance";
import EmployeesCompensationInsurance from "./views/Footer Pages/EmployeesCompensationInsurance";
import MarineCargoInsurance from "./views/Footer Pages/MarineCargoInsurance";
import MedicalInsurance from "./views/Footer Pages/MedicalInsurance";
import CommercialGeneralLiabilityInsurance from "./views/Footer Pages/CommercialGeneralLiabilityInsurance";
import ProductLiabilityInsurance from "./views/Footer Pages/ProductLiabilityInsurance";
import ProfessionalIndemnityInsurance from "./views/Footer Pages/ProfessionalIndemnityInsurance";
import CyberCrimeInsurance from "./views/Footer Pages/CyberCrimeInsurance";
import IndividualHealthInsurance from "./views/Footer Pages/IndividualHealthInsurance";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
          <Route path="terms&conditions" element={<TermsAndConditions />} />
          <Route path="become-a-posp" element={<BecomePosp />} />
          <Route path="posp-login" element={<PospLogin />} />
          <Route path="claim-assistance" element={<ClaimAssistance />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/ourTeam" element={<OurTeam />} />
          <Route path="/health-insurance" element={<HealthInsurance />} />
          <Route path="/bike-insurance" element={<BikeInsurance />} />
          <Route path="/car-insurance" element={<CarInsurance />} />
          <Route path="/commercial-vehical-insurance" element={<CommercialVehicalInsurance />} />
          <Route path="/topup-insurance" element={<TopupInsurance />} />
          <Route path="/travel-insurance" element={<TravelInsurance/>} />
          <Route path="/personal-accident-insurance" element={<PersonalAccidentInsurance />} />
          <Route path="/group-mediclaim-insurance" element={<GroupMediclaim />} />
          <Route path="/individual-health-insurance" element={<IndividualHealthInsurance />} />
          <Route path="/fire-insurance" element={<FireInsurance />} />
          <Route path="/marine-insurance" element={<MarineInsurance />} />
          <Route path="/marine-cargo-insurance" element={<MarineCargoInsurance />} />
          <Route path="/contractor-insurance" element={<ContractorInsurance />} />
          <Route path="/eraction-all-insurance" element={<ErectionAllInsurance />} />
          <Route path="/employees-compensation-insurance" element={<EmployeesCompensationInsurance />} />
          <Route path="/medical-insurance" element={<MedicalInsurance />} />
          <Route path="/commercial-general-liability-insurance" element={<CommercialGeneralLiabilityInsurance />} />
          <Route path="/product-liability-insurance" element={<ProductLiabilityInsurance />} />
          <Route path="/professional-indemnity-insurance" element={<ProfessionalIndemnityInsurance />} />
          <Route path="/cyber-crime-insurance" element={<CyberCrimeInsurance />} />
        
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
