export const GroupHealthInsuranceData = [
      {
        componentType: "title",
        content: "Group", 
        spantext:"  health insurance"
      }, 
      {
        componentType: "paragraph",
        content:`For the purpose of group insurance, Insurance Regulatory and Development Authority of
        India has classified groups as employer-employee groups or non-employer – employee
        groups under its group insurance guidelines.`
      },
      {
        componentType: "paragraph",
        content:`While employer-employee group insurance is widely marketed, we also see non-employee
        group when holders of credit cards of a bank or account holders of a bank or members of
        social organization or cultural organization are provided health insurance cover by the bank
        or the association. In employer-employee groups, group health insurance cover can be
        provided to employees as an employee welfare measure. In non-employee groups, group
        health insurance cover can be provided as a privilege for being a member of the group.`
      },
      {
        componentType: "title",
        content: "Functioning of group", 
        spantext:"  health insurance:"
      },
      {
        componentType: "paragraph",
        content:`Group Insurance policy operates on master policy concept. One master policy will be issued
        in the name of the employer, with the employees being the beneficiaries of insurance.
        Under this master policy a list of active employees, who are covered under the policy, is
        maintained.`
      },
      {
        componentType: "paragraph",
        content:`Usually, the Manager of the human resource dept. of the employer is responsible for
        interacting with the insurance companies and negotiate the terms, conditions, coverage and
        premium and subsequently manage the entire process, on behalf of the employees and
        address their concerns and queries regarding coverage, network hospitals, issues regarding
        cashless hospitlisation, delay in reimbursement of medical expenses etc.`
      },
      {
        componentType: "paragraph",
        content:`While policy terms, conditions and coverages operate similar to individual health insurance
        policy the designated manager of the employer need to draw a Standard Operating
        Procedure (SOP) in discussion with the insurer or insurance broker so as to ensure that no
        employee is left uncovered during their employment period. During a given policy period,
        new employees would join and existing employees would leave. This would result in
        addition of employees into and deletion of employee from the insurance cover. Such
        information needs to be regularly reconciled with the insurers in terms of both the
        employees and premium.`
      }
      
      
]