import { useState } from "react";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { Button } from "primereact/button";
import "./styles.css";
import headerNavList from "../../constants/header-navlist";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isMenuVisible, setMenuVisible] = useState(false); // Track menu visibility

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  return (
    <header className="w-full py-3 px-2 lg:px-6 m-auto App-header flex justify-content-between align-items-center sticky top-0 z-5 gap-3">
      <div className="logo-container">
        <img
          src={"/insuright_logo.svg"}
          alt="logo"
          onClick={() => navigate("/")}
          className="cursor-pointer"
        />
      </div>
      
      {/* On large screens (lg, xl), show the navigation list */}
      <div className="flex gap-3 align-items-center">
        <ul className="list-none gap-1 m-0 hidden lg:flex align-items-center">
          {headerNavList?.map((item, i) => (
            <li key={i} className={`nav-option cursor-pointer`}>
              {item.isExternal ? (
                <a
                  href={item.route}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="header-link"
                >
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </a>
              ) : (
                <Link to={item.route} className="header-link">
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>

        {/* On smaller screens (md, sm), show hamburger icon */}
        <span className>
          <Button
            className="humburger-btn"
            icon={<Menu width={16} height={16} />}
            text
            onClick={toggleMenu} // Toggle the dropdown menu on hamburger click
          />
        </span>
      </div>

        {/* Mobile Dropdown Menu */}
      <div className={`mobile-dropdown-menu lg:hidden ${isMenuVisible ? 'visible' : ''}`}>
        <div className="mobile-panel-header">
          {/* Insuright Logo */}
          <img
            src="/insuright_logo.svg"
            alt="Insuright Logo"
            className="insuright-logo-sidebar"
          />
          {/* Close Icon */}
          <Button
            icon="pi pi-times" // Close icon from PrimeIcons
            className="close-btn"
            onClick={() => setMenuVisible(false)} // Close the menu
          />
        </div>
        <ul className="list-none gap-1 m-0 flex flex-column">
          {headerNavList?.map((item, i) => (
            <li key={i} className={`nav-option cursor-pointer`}>
              {item.isExternal ? (
                <a
                  href={item.route}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="header-link"
                >
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </a>
              ) : (
                <Link to={item.route} className="header-link">
                  <div className="flex align-items-center gap-2">
                    {item.icon && <i className={item.icon} />}
                    <span
                      className={`${
                        item.route === pathname ? "nav-highlight" : "dropdown-nav"
                      }`}
                    >
                      {item.label}
                    </span>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}
