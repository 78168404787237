import { Button } from "primereact/button";
import { ReactComponent as WhiteStar } from "../../assets/icons/white_star.svg";
import { Link } from "react-router-dom";
import FooterLinkTitle from "./components/FooterLinkTitle";

export default function Footer() {
  return (
    <>
      <div className="relative py-8">
        <div className="static lg:absolute footer-abs w-11 lg:w-10 py-4 px-4 lg:px-6">
          <div className="flex flex-column lg:flex-row justify-content-center gap-3 lg:justify-content-between align-items-center flex-wrap">
            <h1 className="white-text w-full  text-5xl m-0 text-center">
              Let’s Secure Your Future with Our Services{" "}
              <span className="absolute top-0">
                <WhiteStar width={32} />
              </span>
            </h1>
            {/* <div className="mr-6">
              <Button
                label="Get Started"
                severity="secondary"
                outlined
                onClick={() => {}}
                className="bg-white"
              />
            </div> */}
          </div>
        </div>
      </div>
      <footer className="footer mt-0 lg:pt-6">
        <div className="pt-6 lg:mt-8">
          <div className="w-11 mx-auto">
            <div className="flex flex-column md:flex-row gap-2 justify-content-between flex-wrap">
              <div className="flex-1">
                <div>
                  <img src="/insuright_logo_dark.svg" alt="logo" />
                </div>
                {/* <p>
                  It is an Insurance Broker licensed under IRDA. Ever since its
                  inception in 2023, the organisation has been growing steadily
                  and phenomenally under the leadership of a group of committed
                  individuals
                </p> */}
                <h3>Address</h3>
                <p>
                  Above ICICI Bank, 3rd Floor, No. 1/33-5, Chancellors Chanmber,
                  Ulsoor Road, Bangalore - 560008
                </p>
              </div>

              <div className="flex-1 lg:pl-8 min-w-max">
                <h4 className="mt-0">COMPANY</h4>
                <ul className="list-none p-0 white-text">
                  
                  <li className="mb-3"
                   onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}>
                    <Link to="terms&conditions">Terms & Conditions</Link>
                  </li>
                  <li className="mb-3"
                   onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}>
                    <Link to="aboutus">About us</Link>
                  </li>
                  <li className="mb-3"
                   onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}>
                    <Link to="ourTeam">Our team</Link>
                  </li>
                  <li className="mb-3"
                   onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}>
                    <Link to="contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>

              <div className="flex-1 min-w-max">
                <h4 className="uppercase mt-0">SUPPORT</h4>
                <ul className="list-none p-0 white-text m-0">
                  <li className="mb-3"
                   onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}>
                    <Link to={"/privacyPolicies"}>Privacy policy</Link>
                  </li>
                  <li className="mb-3"
                  >
                    <Link to="#">Claims</Link>
                  </li>
                </ul>
              </div>
              <div className="flex-1 min-w-max ">
                <div>
                  <h4 className="mt-0">Contact Us</h4>
                  <p>
                    <bold>Email</bold> : contact@insuright.com
                  </p>
                  <p>
                    <bold>Phone</bold> : +91 8147829997
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-11 mx-auto my-6">
          <FooterLinkTitle />
        </div>

        <div className="w-11 mx-auto mb-6">
          <div className="flex flex-column md:flex-row gap-2 lg:gap-3 flex-wrap">
            <div className="w-3 min-w-max">
              <h4 className="mt-0 uppercase">Private Cars</h4>
              <ul className="list-none p-0 white-text">
                <li
                  className="mb-3"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to="/bike-insurance">
                    Two wheeler <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li
                  className="mb-3"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Link to="/car-insurance">
                    Private car <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/commercial-vehical-insurance">
                    Commercial vehicle <i className="pi pi-angle-down" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-3 min-w-max">
              <h4 className="mt-0 uppercase">HEALTH INSURANCE</h4>
              <ul className="list-none p-0 white-text">
              <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/topup-insurance">
                    Top up Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/travel-insurance">
                    Travel Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/personal-accident-insurance">
                    Personal Accident <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/employees-compensation-insurance">
                  Employee’s Compensation Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/medical-insurance">
                  Medical Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/group-mediclaim-insurance">
                  Group Mediclaim {" "}
                    <i className="pi pi-angle-down" />
                  </Link>
                  
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/individual-health-insurance">
                  Individual Health Insurance {" "}
                    <i className="pi pi-angle-down" />
                  </Link>
                  
                </li>
              </ul>
            </div>
            <div className="w-3 min-w-max">
              <h4 className="mt-0">HOME INSURANCE</h4>
              <ul className="list-none p-0 white-text">
              <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/fire-insurance">
                    Fire Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/marine-insurance">
                    Marine Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/marine-cargo-insurance">
                    Marine Cargo Insurance <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/contractor-insurance">
                    Contractors All Risk <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/eraction-all-insurance">
                    Erection All Risk <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/commercial-general-liability-insurance">
                  Commercial General Liability{" "} <i className="pi pi-angle-down" />
                  </Link>
                 
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/professional-indemnity-insurance">
                  Professional Indemnity{" "} <i className="pi pi-angle-down" />
                  </Link>
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/product-liability-insurance">
                  Product Liability{" "} <i className="pi pi-angle-down" />
                  </Link>
                  
                </li>
                <li className="mb-3"
                 onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                >
                  <Link to="/cyber-crime-insurance">
                  Cyber Crime{" "} <i className="pi pi-angle-down" />
                  </Link>
                  
                </li>
                
              </ul>
            </div>
          </div>
        </div>
        <p className="w-11 mx-auto white-text">
        Insuright Insurance Broking Pvt Ltd [CIN: U67200WB2021PTC250453], IRDAI Direct Broker Code: IRDA/DB 936/2022, License No: 871, Valid till: 06/01/2023 to 05/01/2026, Category: Direct Broker (General). Insurance is the subject matter of solicitation.  All product information displayed is from the respective Insurance companies.
        </p>

        <div className="copyright-container">
          <div className="w-11 mx-auto p-3">
            <div className="flex gap-2 align-items-center  justify-content-center flex-wrap">
              <p className="text-sm m-0">
                &copy; 2024 Insuright. All Rights Reserved
              </p>
              {/* <p className="text-sm m-0">
              <a href="#">Terms & Conditions</a>
              <a href="#" className="ml-6">
                Pricay & policy
              </a>
            </p> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
