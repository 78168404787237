
export const CarInsuranceData = [
    {
        componentType: "title",
        content: " Motor",
        spantext: " vehicle insurance"
      },
      {
        componentType: "paragraph",
        content: `In spite of you adopting best of the methods to protect your vehicle, accidents and mishaps happen, leading to damage to vehicle and injuries/death to persons. Proper insurance cover can go a long way in dealing with the financial consequences of such accidents. Basically, a motor vehicle insurance deals with damages to car due to various perils and the legal compensation to victims of accidents caused by your vehicle. However, depending on the type of vehicle &amp; use of vehicle the terms of cover vary. Also, coverages addressing various situations are also provided by insurance companies.`
      },
      {
        componentType: "paragraph",
        content:`Insuright Brokers provides you most appropriate advices through understanding your insurance
        needs and suggest you the best possible coverages available in the
        market. As long as you are the
        owner of the vehicle, we will be there with you, from the proposal stage, negotiation stage, assisting
        you in finalizing the best of the
        coverage; terms and conditions, claims support, assist you in disputer solution and during the renewal of the policy.`
      },
      {
        componentType: "title",
        content: " Private",
        spantext: " car insurance"
      },
      {
        componentType: "paragraph",
        content:`Though there is no legal definition of what is a private car, a car is deemed to be a private car if it is
        not permitted to be used for carriage of passengers for hire or
        reward. If a car is permitted to be
        used for carriage of passengers for hire or reward eg. taxi, maxi-cab then it is called as commercial
        vehicle. Premium calculation
        and certain terms and conditions in private car insurance and
        commercial vehicle insurance differ on account of this distinction.`
      },
      {
        componentType: "paragraph",
        content:`As per Motor Vehicles Act, 1988 no vehicle is permitted to ply in a public place unless a valid
        insurance policy covers the vehicle.`
      },
      {
        componentType: "paragraph",
        content:`The coverages for a private car can be broadly classified into 4 sections.`
      },
      {
        componentType: "list",
        content: [
          `Own-damage section`,
          `Third party section`,
          `Personal Accident cover for the owner cum driver section`,
          `Special covers through Add Ons section`
        ]
      },
      {
        componentType: "title",
        content: " Own ",
        spantext: " damage section"
      },
      {
        componentType: "paragraph",
        content:`provides cover against the financial loss to insured in repairing / replacing the
        parts damaged due to accidents, fire, explosion lightning, riots, strikes, malicious acts,
        natural causes
        like storm, flood etc., earthquake, terrorism, burglary, theft, landslide, rockslides.
        Total loss of the vehicle due to above causes is also covered.`
      },
      {
        componentType: "paragraph",
        content:`However, this section does not cover depreciation, wear &amp; tear, failures, breakages, consequential
        loss, damages to tyres and tubes(unless vehicle is also
        damaged at the same time, subject to 50%
        depreciation), loss when driving under influence of alcohol, loss when driving with invalid license.`
      },
      {
        componentType: "title",
        content: " Personal  ",
        spantext: " Accident Section"
      },
      {
        componentType: "paragraph",
        content:`for owner cum driver provides compensation for death or disability to
        owner cum driver of the insured car due to an accident while driving / mounting into /
        dismounting
        from the vehicle.`
      },
      {
        componentType: "paragraph",
        content:`Exclusions applicable to all the sections includes`
      },
      {
        componentType: "list",
        content: [
          `Loss or damages to vehicle / liability caused or created outside the geographical limits.`,
          `Any claim arising out of contractual liability`,
          `Loss or damage or liability caused or created whilst using the vehicle arising out of use of the
          vehicle in violation of the “Limitations as
          to Use” clause of the vehicle.`,
          `Loss or damage or liability caused or created whilst driving vehicle in violation of “Driver’s
          Clause” of the policy.`,
          `Any consequential loss resulting from any loss or damage to the vehicle.`,
          `Any loss or damage caused by nuclear events.`,
          `Any loss or damage due war and warlike operations.`,
        ]
      },
      {
        componentType: "title",
        content: " Add On Covers : ",
      },
      {
        componentType: "paragraph",
        content:`To address certain “pain” points which resulted in reduced claim payments to insured, insurers have
        come up with certain additional covers to address such
        “pain points” at an additional premium.
        Please note that an insurer need not offer all the add on covers available in the market. An add on
        cover offered by one
        insurer need not be offered by another insurer. Also there could be differences
        in the terms and conditions of the Add On covers offered by the insurers.
        Hence the coverage terms
        need to be carefully scrutinized for its suitability.`
      },
      {
        componentType: "title",
        content: " Nil  ",
        spantext: " Depreciation cover"
      },
      {
        componentType: "paragraph",
        content:`In assessing the claim payable for damages, depreciation applicable to
        various parts replaced is deducted in the claim assessment. This depreciation rate applied
        can be as
        high as 50% and as a result substantial amount of claim payable gets reduced. With this add on
        cover,no depreciation is applied on the parts replaced.`
      },
      {
        componentType: "title",
        content: " Engine   ",
        spantext: " Protection cover"
      },
      {
        componentType: "paragraph",
        content:`This add on covers damage to engine arising out of water ingression or
        seizure of engine / gear box due to leakage of lubricating oil caused by an external impact
        on the
        engine / gear box in an accident.`
      },
      {
        componentType: "title",
        content: " No-claim Bonus",
        spantext: " Protection cover"
      },
      {
        componentType: "paragraph",
        content:`Insured loses the no-claim bonus earned for claim free years,
        whenever a claim is made, in the next renewal of the policy. Losing of no-claim bonus earned would
        result in increase of premium while renewing the policy. In order that no-claim bonus is not lost
        (protected)even if a claim is made under the policy, this cover is
        provided.`
      },
      {
        componentType: "title",
        content: " Consumables",
        spantext: " cover"
      },
      {
        componentType: "paragraph",
        content:`Certain parts in the course of repairs need to be replaced even through they
        were not damaged in an accident. These parts which are not included in the claim
        assessment, by
        virtue of this cover can be included in the claim assessment. Examples of such consumables are engine oil, gear box oil, nuts, bolts, screws, clips,
        oil filters, distilled water etc. which need to be
        replaced while repairing the damaged vehicle.`
      },
      {
        componentType: "title",
        content: " Road-side ",
        spantext: " Assistance cover"
      },
      {
        componentType: "paragraph",
        content:`Imagine the vehicle is immobilized due to various reasons like flat tyre,
        drained battery, empty fuel tank, loss of key or key locked inside the car. Insured would like
        to seek
        help, but would be concerned about approaching any stranger. An assured assistance to deal with such situations would greatly relieve insured of the
        uncertainty surrounding such situations. That
        assured assistance comes in the shape of road-side assistance cover which would help to take
        control of the vehicle
        and the situation.`
      },
      {
        componentType: "title",
        content: " Spare Keys ",
        spantext: " Replacement cover"
      },
      {
        componentType: "paragraph",
        content:`In case keys of the vehicle is lost, this cover will pay the cost of
        replacement of the keys.`
      },
      {
        componentType: "title",
        content: " Return to ",
        spantext: " Invoice cover"
      },
      {
        componentType: "paragraph",
        content:`The insured value of the vehicle under the policy decreases with the ageing
        of the vehicle due to depreciation factor in the policy. In case of total loss / theft of the
        vehicle the
        amount of claim payable is reduced depending on the age of the vehicle. This cover would pay the invoice cost of the vehicle which was paid at the time
        of purchasing the vehicle. The cost includes ex-
        showroom price, road tax &amp ;registration costs of the vehicle.`
      },
      {
        componentType: "title",
        content: " Personal Laptop ",
        spantext: "  and Mobile cover"
      },
      {
        componentType: "paragraph",
        content:`This cover would pays for loss of personal laptop &amp; mobile kept
        in the vehicle.`
      },
      {
        componentType: "title",
        content: " Daily  ",
        spantext: "  Cash Allowance"
      },
      {
        componentType: "paragraph",
        content:`In this cover, insurer would pay daily cash allowance to insured when the
        insured vehicle is undergoing repairs due to a road accident.`
      },
]