
export const TopupInsuranceData = [
  
  {
    componentType: "paragraph",
    content: `A top-up health insurance is a supplementary health insurance, providing additional amount of
    insurance, over and above the base insurance policy. Contractually
     a top-up insurance health
    insurance policy is not connected to base insurance policy, but sum insured under the base health
    insurance policy can be treated as a
     deductible for top up health insurance policy. By this
    arrangement insured's overall sum insured limit under health insurance is enhanced but at a
    reduced premium,
     because the deductibles in top up health insurance plan reduce the premium.
    This has become a very attractive strategy for health insurance customers.`
  },
  {
    componentType: "paragraph",
    content: `Since top up health insurance policy is also a health insurance policy, its structure, terms and
    conditions are similar to any standard health insurance plan.
    Any person familiar with the working of
    any of the standard health insurance plan would have no difficulty in understanding the terms and
    conditions of top-up
    health insurance plans.`
  },
  {
    componentType: "paragraph",
    content: `When exploring top-up health insurance, we come across a term “super top-up” health insurance.
    The difference between top-up health insurance and super
    top-up is given below:.`
  },
  {
    componentType: "table",
    className: "tableClass",
    headers: [
      {
        "title": "Basis",
        "subHeaders": []
      },
      {
        "title": "Top-up Plan",
        "subHeaders": []
      },
      {
        "title": "Super Top-up Plan",
        "subHeaders": []
      },
    ],
    tableRows : [
      ["Deductible" , `For each and every claim the deductible is applied`, `Deductible is applied on total of all claims put together in a given policy period. In other words, the deductible is applied only once in a policy year. `],
      ["Premium", "Less premium", "More premium"]

    ]
  },
  {
    componentType: "paragraph",
    content: `When choosing a top-up plan it is advisable to verify whether the chosen plan applies deductible on each and every claim or once on the total of all the claims put together.`
  },
 
];


export const TopupInsuranceCardData =[]
 