import React from 'react'
import classes from "./ourTeam.module.css"
import ourTeam from "../../utils/ourTeam.json"
import CustomeParagraph from '../../components/CustomParagraph/CustomeParagraph'
import CustomList from '../../components/CustomList/CustomList'

const OurTeam = () => {
    return (
        <div className={classes.container} >
            <h3 className={classes.ourTeam} >Our Team</h3>
            {ourTeam.map((item,index)=>{
                switch (item.componentType){
    
                    case "title":
                        return (
                            <div key={index}> 
                            <CustomeParagraph  context={item.context} className={item.className} style={item.style} />
                            </div>
                        )
                        break;
                    case "paragraph":
                        return (
                            <div key={index}>
                            <CustomeParagraph  context={item.context} className={item.className} />
                            </div>
                        )
                        break;
                    case 'list':  // Handle list case
                        return (
                        <div key={index}>
                            <CustomList listItems={item.context} className={item.className} />
                        </div>
            );
                }
            })}
        </div>
      )
}

export default OurTeam