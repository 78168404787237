export const MedicalInsuranceData = [
      
      {
        componentType: "paragraph",
        content:"Considering the rise in healthcare costs, higher incidences of lifestyle diseases, the availability of various healthcare procedures, evolution of healthcare service to an industry level status etc. has made health insurance as the most impactful insurance schemes. Further healthcare insurance coverages have also evolved keeping pace with the developments in healthcare sector through extensive customization. Developments over last two decades in health insurance includes introduction of Third-Party Administrators, group insurance schemes, networking of hospitals for cashless service, licensing of exclusive health insurance companies, introduction of various health insurance schemes both by Central and State Governments. Such developments have brought very high visibility to health insurance and has made it a priority insurance protection cover that is being sought.Primarily, health insurance policies are designed to cover hospitalization expenses incurred for the treatment of an ailment in a hospital. All other covers, which could be add on covers or extensions, are related to this hospitlisation."
      },
      {
        componentType: "title",
        content: "Hospitalisation", 
        spantext:"  expenses"
      },
      {
        componentType: "paragraph",
        content: "Cover for hospitlisation expenses includes bed charges, nursing charges, ICU charges, OT charges, consultation fees, attending physician charges, surgeon fees, anesthesia, oxygen, blood bank, implants, diagnostics, pharmacy, consumables etc.",
      },
      {
        componentType: "paragraph",
        content: `Currently, hospitlisation refers to admission and treatment in the hospitals for a minimum period of 24 hrs.`
      },
      {
        componentType: "title",
        content: "Pre & post-hospitlisation", 
        spantext:"  expenses"
      }, 
      {
        componentType: "paragraph",
        content: `Expenses incurred prior to hospitlisation and subsequent to hospitlisation are referred as pre-hospitlisation and post-hospitlisation respectively. Pre-hospitlisation expenses is largely incurred for diagnosis and consultation prior to hospitlisation. Post-hospitlisation expenses is incurred for follow-up treatments, consultation after discharge from hospital. These expenses are covered in the policy for a certain specified period. It could be 15 days / 30 days / 45 days / 60 days for pre-hospitalization & 30 days / 45 days / 60 days / 75 days for post-hospitlisation.  Many a times the period covered for pre & post-hospitlisation is expressed in the format “15 / 30” . “15” refers to coverage for expenses incurred 15 days of prior to hospitlisation & “30” refers to coverage for expenses incurred for 30 days after hospitlisation.`, 
      }, 
      {
        componentType: "title",
        content: "Daycare ", 
        spantext:"procedures"
      }, 
      {
        componentType: "paragraph",
        content: "With the advent of new medical treatment procedures, many treatments do not require hospitlisation for more than 24 hrs. Patient undergoing such treatments are treated and discharged on the same day. These treatment procedures are called as daycare procedures. Insurance polices provides a list of such daycare procedures covered.",
      },
      {
        componentType: "title",
        content: "Pre-existing  ", 
        spantext:"diseases"
      },
      {
        componentType: "paragraph",
        content: `It is very important to understand how policy defines pre-existing diseases because insurance policies do not cover pre-existing diseases. However, policy covers such pre-existing diseases after a certain waiting period from the date of commencement of first insurance cover. This waiting period could be upto 48 months. If the insurance policy is not renewed, then waiting period will be reset and will be considered from date of commencement of subsequent policy.`
      },
      {
        componentType: "paragraph",
        content: `Hence to remove the limitation imposed by the waiting period it is very essential that the policy is renewed without any break.`
      },
      {
        componentType: "title",
        content: "Maternity ", 
        spantext:" Expenses"
      },
      {
        componentType: "paragraph",
        content: `Customisation of health insurance over a period of time has resulted in covering maternity expenses in certain health insurance policies. Customers who are looking for maternity cover in their health insurance programme should specifically examine whether maternity cover is included or excluded and if included terms and conditions of maternity cover.`
      },
      {
        componentType: "title",
        content: "Restoration of sum  ", 
        spantext:" insured"
      },
      {
        componentType: "paragraph",
        content: `In health insurance the sum insured (amount for which insurance policy has been taken) gets reduced to the extent of claim paid. For example if the sum insured under the policy is Rs 5 lakhs, and the claim paid in the 6th month of the policy is Rs 2 lakhs, then for the balance period of the policy the sum insured available for the insured under this policy is Rs 4  lakhs. If the policy has a provision of restoration of sum insured then sum insured under the policy gets restored to original sum insured of Rs 5 lakhs.  Customer looking for this feature should specifically check policy terms and understand the terms and conditions on restoration of sum insured following a claim.`
      },
      {
        componentType: "title",
        content: "Network ", 
        spantext:" hospitals"
      },
      {
        componentType: "paragraph",
        content: ` Insurance companies include hospitals in their network to facilitate cashless payment of medical expenditure when treated in such hospitals. In this arrangement insurance company directly pays the hospitlisation expenses to the hospital to the extent admitted under the policy.  Insurance companies provides the list of such networked hospitals in their websites. Customers can check and confirm whether their preferred hospitals are included in the list.`
      },
      {
        componentType: "title",
        content: "Exclusions ", 
      },
      {
        componentType: "paragraph",
        content: `Health insurance policies has many exclusions, some of which are listed below. It is essential for the customers to examine and understand the exclusions so that they have a realistic expectations on the policy coverage.`
      },
      {
        componentType: "list",
        content: [
          `Pre-existing diseases`,
          `Specific diseases subject to waiting period`,
          `Expenses primarily incurred for diagnostics`,
          `Cosmetic or plastic surgery`,
          `Dental treatment`,
          `IVF, birth control`,
          `OPD treatmen`,
        ]
      },
      {
        componentType: "paragraph",
        content: "Since health insurance covers are amenable for high customisation, varieties of covers are available in the market. Hence it is advisable that the customers analyse the policies available in the market before short listing them as suitable policies.",
      },
      {
        componentType: "paragraph",
        content: "The above information is provided to give the interested persons an outline of a typical health insurance policy. For any clarification wordings, terms and conditions of a specific policy need to be examined to understand the implications of the policy terms.",
      },
      
]