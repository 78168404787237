
import React from "react";
import InsuranceHeader from "../../../components/InsuranceHeader";
import List from "../../../components/List/List";
import Paragraph from "../../../components/Paragraph";
import Title from "../../../components/Title";
import TriImageCompination from "../../../components/TriImageGalary";
import styles from "./index.module.css";
import { IndividualHealthInsuranceData } from "../../../Data/IndividualHealthInsuranceData";

const IndividualHealthInsurance = () => {
  return (
    <div className="flex flex-column align-items-center justify-content-center gap-2">
      <InsuranceHeader  />
      <p className={styles.header}>
        Individual <span className="green-text ">health insurance service</span>
      </p>
      <div className="mb-0 lg:mb-8  w-full">
        <TriImageCompination />
      </div>

      <div className="w-11 flex flex-column  gap-2 mt-0 lg:mt-8">
        {IndividualHealthInsuranceData.map((item, index) => {
          switch (item.componentType) {
            case "title":
              return (
                <div key={index}>
                  <Title content={item.content} spanText={item.spantext} />{" "}
                </div>
              );
              
            case "paragraph":
              return (
                <div key={index}>
                  <Paragraph content={item.content} />
                </div>
              );
             
            case "list":
              return (
                <div key={index}>
                  <List items={item.content} />
                </div>
              );
              default:
                return null;
          }
        })}
      </div>
    </div>
  );
}

export default IndividualHealthInsurance