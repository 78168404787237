export const MarineCargoInsuranceData = [
      
      {
        componentType: "paragraph",
        content:"Specific Marine Cargo Policy also known as Specific Marine Policy, covers goods (machinery, raw materials, finished goods etc) during transit under a contract of affreightment."
      },
      {
        componentType: "paragraph",
        content:"Premium for all such consignments have to be paid before the start of the journey."
      },
      {
        componentType: "title",
        content: "  What does this", 
        spantext:" Policy cover?"
      },
      {
        componentType: "paragraph",
        content: "This Policy covers physical loss or damage to insured goods during transits by (a) Sea (b) Post or parcels (c) Rail/Road/Air. The scope of cover shall be determined by the Institute Clauses attached to the Policy.",
      },
      {
        componentType: "paragraph",
        content: `All Overseas Transits are subject to Institute Cargo Clauses A,B &C given by Institute of London Underwriters. The brief coverage is as under:`
      },
      {
        componentType: "table",
        className: "tableClass",
        headers: [
          {
            "title": "Risk",
            "subHeaders": ["(Proximate Cause)"]
          },
          {
            "title": "Institute Cargo Clauses",
            "subHeaders": ["A (All risk Cover)","B (WiderCover)","C (Basic Cover)"]
          },
        ],
        tableRows : [
          ["Stranding , Grounding, Sinking or Capsizing", "Yes", "Yes","Yes"],
          ["Overturning or Derailment of LandConveyance", "Yes", "Yes", "Yes"],
          ["Collision of Ship or Craft with another Ship orCraft", "Yes", "Yes", "Yes"],
          ["Contact of Ship, Craft or Conveyance withanything other than Ship or Craft (excludes Water but not Ice)","Yes", "Yes", "Yes"],
          ["Discharge of Cargo at Port of Distress","Yes", "Yes", "Yes"],
          ["Loss overboard during Loading/Discharge(total loss only).","Yes", "Yes", "No"],
          ["Fire or Explosion","Yes", "Yes", "Yes"],
          ["Malicious Damage","Yes", "Yes", "No*"],
          ["Theft/ Pilferage","Yes", "No", "No"],
          ["General Average Sacrifice","Yes", "Yes", "Yes"],
          ["Jettison","Yes", "Yes", "Yes"],
          ["Washing Overboard (deck cargo)","Yes", "Yes", "No"],
          ["War Risks","No*", "No*", "No*"],
          ["Seawater entering Ship, Craft, Hold, Conveyance Container Lift Van or Place of Storage","Yes", "Yes", "No"],
          ["River or Lake Water entering same","Yes", "Yes", "No"],
    
        ]
      },
      {
        componentType: "paragraph",
        content: "* Can be bought back",
      },
      {
        componentType: "paragraph",
        content: `Goods (merchandise) dispatched by rail or road from any place in India to any place in India (not in conjunction with an overseas voyage) are subject to Inland Transit (Rail/Road) Clause A & B . The brief coverage is as under:`
      },
      {
        componentType: "paragraph",
        content: `(a) Inland Transit (Rail or Road) Clause – A (All Risks): Covers All Risks of loss ordamage subject to exclusions.`
      },
      {
        componentType: "paragraph",
        content: `(b) Inland Transit (Rail or Road) Clause – B (Basic Cover): Covers Physical loss ordamage caused by:`
      },
      {
        componentType: "list",
        content: [
          `Fire, Lightning, Breakage of Bridges`,
          `Collision with or by the carrying vehicle, derailment or accidents of the likenature to the carrying railway wagon/vehicle.`,
        ]
      },
      {
        componentType: "paragraph",
        content: "(c) Inland Transit (Rail or Road) Clause – C: Covers Physical loss or damage caused by",
      },
      {
        componentType: "list",
        content: [
          `fire`,
          `Lightning`,
        ]
      },
      {
        componentType: "title",
        content: "Optional ",
        spantext:"Extensions"
      },
      {
        componentType: "paragraph",
        content: `(a) For Inland Transit: Strike, Riot and Civil Commotion`
      },
      {
        componentType: "paragraph",
        content: `(b) For Oversea Transit:`
      },
      {
        componentType: "list",
        content: [
          `War, Strike, Riot and Civil Commotion`,
          `Seller’s Interest Insurance ( for Exports with C&F or FOB terms only)`,
          `Lightning`,
        ]
      },
      {
        componentType: "title",
        content: " Other Salient ",
        spantext:"Features"
      },
      {
        componentType: "list",
        content: [
          `The policy is assignable`,
          `The Sum is fixed on ‘Agreed Value’ basis. Normally with a margin of 10% on invoice price for incidental expenses`,
          `The premium rates depends on factors like nature of cargo, scope of cover, packing, mode of conveyance, distance and past claims experience`,
          `Policies can be issued for (a) Specific Transit. (b) Marine Open policy with monthly declarations and (c) Sales Turn Over Policy with quarterly statements`,
        ]
      },
      {
        componentType: "title",
        content: "Basis of ",
        spantext:"Settlement"
      },
      {
        componentType: "paragraph",
        content: "Agreed Value Basis",
      },
      {
        componentType: "title",
        content: "Salvage",
      },
      {
        componentType: "paragraph",
        content: "Salvage is the amount that is assessed which the damaged asset will fetch in the open market. This amount is deducted from the claim amount.",
      },
      
      {
        componentType: "title",
        content: "The Policy ",
        spantext:"does not cover the following"
      },
      {
        componentType: "list",
        content: [
          `Willful misconduct`,
          `Ordinary leakage/loss in weight or volume , wear and tear`,
          `Improper packing`,
          `Inherent vice`,
          `Delay`,
          `Insolvency/financial default of carriers`,
          `War, Strike, Riot and Civil Commotion`,
        ]
      },
]