import React from "react";
import styles from "./styles.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

const CustomForm = ({ onSubmit,title="" }) => {
  const defaultValues = {
    name: "",
    email: "",
    number: "",
    subject: "",
    requirement: ""
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm(defaultValues);

  const handleFormSubmit = (data) => {
      const { name, email, number, subject, requirement } = data;
  
      // Format the message for WhatsApp
      const messageText = `Hello! I have a query regarding: *${subject}*.
      
      - Name: ${name}
      - Email: ${email}
      - Mobile: ${number}
      - Message: ${requirement}
      
      Please assist me with my query.`;
  
      // URL encode the message and open WhatsApp
      const whatsappUrl = `https://wa.me/918147829997?text=${encodeURIComponent(messageText)}`;
      window.open(whatsappUrl, "_blank");
  
      reset({
        name: "",
        email: "",
        number: "",
        subject: "",
        requirement: ""
      });
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-full flex flex-column align-items-center xl:align-items-start gap-4"
    >
      <p className={styles.header}>Get a {title} insurance cover</p>
      <div className="p-field flex flex-column w-full">
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required." }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Name"
              className={classNames(styles.input,{ "p-invalid": errors.name })}
            />
          )}
        />
        {errors.name && (
          <small className="p-error">{errors.name.message}</small>
        )}
      </div>
      <div className="p-field flex flex-column w-full">
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address."
            }
          }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Email"
              className={classNames(styles.input,{ "p-invalid": errors.email })}
            />
          )}
        />
        {errors.email && (
          <small className="p-error">{errors.email.message}</small>
        )}
      </div>
      <div className="p-field flex flex-column w-full">
        <Controller
          name="number"
          control={control}
          rules={{
            required: "Number is required.",
            pattern: {
              value: /^\d{10}$/,
              message: "Number must be 10 digits."
            }
          }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Number"
              className={classNames(styles.input,{ "p-invalid": errors.number })}
            />
          )}
        />
        {errors.number && (
          <small className="p-error">{errors.number.message}</small>
        )}
      </div>
      <div className="p-field flex flex-column w-full">
        <Controller
          name="subject"
          control={control}
          rules={{ required: "Subject is required." }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Subject"
              className={classNames(styles.input,{ "p-invalid": errors.subject })}
            />
          )}
        />
        {errors.email && (
          <small className="p-error">{errors.subject.message}</small>
        )}
      </div>
      <div className="p-field flex flex-column w-full">
        <Controller
          name="requirement"
          control={control}
          rules={{ required: "Requirement is required." }}
          render={({ field }) => (
            <InputTextarea
              {...field}
              placeholder="Requirement"
              rows={5}
              autoResize
              className={classNames(styles.input,{ "p-invalid": errors.requirement }) }
            />
          )}
        />
        {errors.requirement && (
          <small className="p-error">{errors.requirement.message}</small>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          label="Get Started Today"
          className={styles.buttonText}
        />
      </div>
    </form>
  );
};

export default CustomForm;
