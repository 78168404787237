function BannerCarousel() {
  return (
    <div className="bg-img">
      <div className="banner">
        <div className="w-11 m-auto flex flex-column justify-content-center banner-info-continer">
          <div className="banner-info w-full lg:w-8">
            <p className="m-0 text-3xl lg:text-5xl">Buy Your</p>
            <h1 className="m-0 text-5xl lg:text-7xl">INSURANCE</h1>
            <p className="m-0 text-3xl lg:text-5xl">
              In a Smart Way, with{" "}
              <span style={{ color: "#06335D" }}>One Click</span>
            </p>
            <small>
            Our customers insure their vehicles in less than 3 minutes. Its that simple…
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerCarousel;
