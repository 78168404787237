import React from 'react'
import classes from "./aboutus.module.css"
import aboutus from "../../utils/aboutus.json"
import CustomeParagraph from '../../components/CustomParagraph/CustomeParagraph'

const AboutUs = () => {
    return (
        <div className={classes.container} >
            <h3 className={classes.aboutus} >About us</h3>
            {aboutus.map((item,index)=>{
                switch (item.componentType){
    
                    case "title":
                        return (
                            <div key={index}> 
                            <CustomeParagraph  context={item.context} className={item.className} style={item.style} />
                            </div>
                        )
                        break;
                    case "paragraph":
                        return (
                            <div key={index}>
                            <CustomeParagraph  context={item.context} className={item.className} />
                            </div>
                        )
                        break;
                }
            })}
        </div>
      )
}

export default AboutUs