export const IndividualHealthInsuranceData = [
      {
        componentType: "title",
        content: "Individual", 
        spantext:"  health insurance"
      }, 
      {
        componentType: "paragraph",
        content:`The insureds, under an individual health insurance plan, includes proposer and members of
        his/her family. Family could include self, spouse, dependent children, dependent parents &amp;
        dependents in-laws.`
      },
      {
        componentType: "paragraph",
        content:`A typical health insurance plan covers medical expenses arising out of hospitalisation. All
        coverages provided are customized around hospitalisation cover. Hence, apart from
        hospitalisation, health insurance police are designed to cover`
      },
      {
        componentType: "list",
        content: [
          `Day Care procedures`,
          `In-patient AYUSH treatment`,
          `Domiciliary hospitalisation`,
          `Pre hospitalisation expenses and post hospitalisation expenses`,
          `DOrgan Donor’s expenses`,
          `Complimentary health checkup`,
          `Ambulance cover`,
          `Daily cash cover`,
          `Maternity expenses`,
          `E-opinion &amp; tele consultation`,
          `Medical Treatments cover`,
        ]
      },
      {
        componentType: "paragraph",
        content:`The coverages listed above are only indicative. Depending on the design of the policy,
        more covers could be provided or covers could be restricted. Since health insurance is a
        highly customisable product there are multiple health insurance options available in the
        market. Even within one product many variants are available. So customers need to
        examine as many products as possible and judge for themselves the most suitable ones.
        It is advisable to take services of experienced agents and brokers who are experienced in
        dealing with health insurance products in understanding the coverage.`
      }
      
]