export const MarineInsuranceData = [
      
      {
        componentType: "paragraph",
        content: `Having marine cargo insurance is no less an investment. Not only does it safeguard your goods against potential risks, but it also gives you peace of mind that you will not have to bear the hefty losses arising due to any damage to your insured cargo resulting from the situations covered under your policy. Let’s try to understand this with an`
      },
      {
        componentType: "paragraph",
        content: " example:", 
      },
      {
        componentType: "title",
        content: " What is Annual ",
        spantext: "Open Policy?"
      },
      {
        componentType: "paragraph",
        content: `An annual open policy or a marine open policy is a type of marine insurance which offers coverage for cargo across multiple shipments made over the course of a year. It is an efficient and cost-effective policy for businesses and individuals who need to make multiple shipments via seaways.`
      },
      {
        componentType: "title",
        content: " How Does an Annual Open ",
        spantext: "Policy Work?"
      },
      {
        componentType: "paragraph",
        content: `Now that we know what is the marine cargo open policy meaning, let us see how it works. You can think of an annual open policy like a subscription-based service. Simply put, you purchase a policy and pay the premium based on the coverage type and level of potential risks.`
      },
      {
        componentType: "paragraph",
        content: `You can adjust your policy premium throughout the tenure of the policy depending on your risk exposure. Basically, you can add or eliminate the coverage areas as per your requirements..`
      },
      {
        componentType: "paragraph",
        content: `The claims for an annual open policy work just like any other policy. However, they are processed on the basis of terms and conditions of specific coverage outlined in your policy documents.`
      },
      {
        componentType: "paragraph",
        content: `Note that the limits, insured sum, and deductibles can vary depending on each coverage area. Therefore, read the policy documents very carefully at the time of purchasing an annual open marine policy.`
      },
      {
        componentType: "title",
        content: "Who Should Buy Annual Marine ",
        spantext: "Open Policy?"
      },
      {
        componentType: "paragraph",
        content: `An Annual Open Policy is suitable for businesses that need continuous coverage for numerous shipments made during the year. You can consider purchasing an annual open policy if you belong to the following categories:
        Growing Businesses: Businesses that are planning to grow and expand can invest in an Annual Open Policy as it can be customized according to their growing requirements.`,
      },
      {
        componentType: "paragraph",
        content: "SMEs (Small and Medium Enterprises): SMEs or startups can also invest in an Annual Open Policy as it allows them to streamline their insurance management by having a single policy for all their cargo. Further, it is cost-effective as well.",
      },
      {
        componentType: "title",
        content: "What is Covered Under Annual",
        spantext: "Open Policy?"
      },
      {
        componentType: "paragraph",
        content: "An Annual Marine Policy offers coverage depending on your requirements and risk exposure. Therefore, the coverage can vary from one policy to another. Following are some common coverage areas included in most policies:",
      },
      {
        componentType: "list",
        content: [
          `Ship Sinking`,
          `Collision`,
          `Natural calamity damage`,
          `Fire accidents`,
          `Stranding`,
          `Jettisons`,
          `Overturning`,
          `Washing overboard`,
          `Forwarding expenses`,
          `Derailment of land transportation`,
          `Loss during loading and unloading`,
          `Cost of reconditioning`,
          `Theft`,
        ]
      },
      {
        componentType: "title",
        content: " What is the Difference Between Open Policy and Open Cover in ",
        spantext: "Marine Insurance?"
      },
      {
        componentType: "paragraph",
        content: `Marine Open Policy and Marine Open Cover policy are two different concepts in Marine Insurance.
        Marine Open Policy is a policy that offers comprehensive coverage for all your shipments made throughout the policy tenure. It usually has a higher sum insured, and the premium is paid in advance depending on the insured sum.`
      },
      {
        componentType: "paragraph",
        content: "On the other hand, Marine Open Cover allows you to have a single policy for your shipments made during the course of 12 months. Simply put, it doesn’t require you to declare a new policy for each shipment.Final Words",
      },
      {
        componentType: "paragraph",
        content: `Businesses and individuals dealing in multiple shipments throughout the year can invest in a reliable annual open policy to save time and effort that would otherwise be spent on insuring goods of each shipment.`
      },
      {
        componentType: "paragraph",
        content: "You can purchase a single policy to cover all your shipments made annually. An annual open policy is cost-efficient, easy to manage, and can be customised according to your risk exposure and requirements. Buy your annual open policy today.",
      },
]