function InsurightInfo() {
  return (
    <>
      <h1 className=" my-3 text-4xl lg:text-6xl">
        Why insuright Insurance Should Be Your{" "}
        <span style={{ color: "#03A67C" }}>Top Choice</span>
      </h1>
      <p className="w-full lg:w-9">
      At <strong> Insuright Insurance </strong>, we understand that every individual, family, and business has unique needs. That's why we offer personalized insurance plans designed to provide you with the coverage that best suits your lifestyle, priorities, and budget.
      </p>
      <p className="w-full lg:w-9">
      Whether you're seeking <strong> auto, home, health </strong>, or <strong> business insurance </strong>, we offer flexible and affordable solutions that protect what matters most to you. Our expert team works closely with you to craft a customized plan that provides peace of mind without compromising on quality or value.
       </p>
    </>
  );
}

export default InsurightInfo;
