export const PersonalAccidentInsuranceData = [
    
      {
        componentType: "paragraph",
        content: `As the name indicates, this insurance provides protection for the financial consequences of
        accidents to individuals insured. Accidents results in injuries or death.
        The injuries can result in
        disability which can be a partial or total and the disability can be a permanent disability or
        temporary disability. Hence the result of an accident
        to the insured can be`
      },
      {
        componentType: "list",
        content: [
          `Death`,
          `Permanent Total Disability`,
          `Permanent Partial Disability`,
          `Temporary Total Disability`,
        ]
      },
      {
        componentType: "paragraph",
        content: `Personal Accident insurance provides financial compensation to the insured in case any of the above
        contingencies resulting from an accident.`
      }, 
      {
        componentType: "paragraph",
        content: `The generally accepted definition of accident is sudden, unforeseen and involuntary event caused by
        external, visible and violent means.`
      },
      {
        componentType: "title",
        content: " Coverage: ",
      },
      {
        componentType: "list",
        content: [
          `If the injuries sustained in an accident causes death within twelve months of the accident,
          entire sum insured is paid.`,
          `If the injuries sustained in an accident result in irrecoverable loss of sight of both eyes, or,
          physical separation of two entire hands or two entire feet, or, of one entire hand
          and one
          entire foot , or, loss of sight of one eye and loss of one entire hand or one foot within twelve
          months ofaccident, entire sum insured is paid`,
          `PIf the injuries sustained in the accident result in irrecoverable loss of sight of both eyes, or,
          loss of use of two entire hands or two entire feet, or, use of one entire hand and
          one entire
          foot , or, loss of sight of one eye and use of one entire hand or use of one foot within twelve
          months of accident, entire sum insured is paid`,
          `If the injuries sustained in the accident result in irrecoverable loss of sight of one eyes, or,
          physical separation of one entire hand or one entire foot within twelve months
          of accident,
          50% of the sum insured is paid.`,
          `If the injuries sustained in the accident result in irrecoverable loss of use of one entire hand
          or one entire foot within twelve months of accident, 50% of the sum insured is paid.`,
          `If the injuries sustained in the accident results in permanent, total, absolute disablement
          consequence of which insured is unable to engage in any employment or
          occupation of any
          description the n a 100% of the sum insured is paid.`,
          `If the injuries sustained within twelve months of the accident results in irrecoverable loss of
          use or physical separation of certain parts of the body leading to permanent partial
          disability, then proportionate compensation is paid.`,
        ]
      },
      {
        componentType: "title",
        content: " Additional covers: ",
      },
      {
        componentType: "list",
        content: [
          `Hospitalisation expenses for the treatment of the injuries sustained in the accident`,
          `Weekly payment, as a percentage of the sum insured, for a defined period, in case the injury
          due to accident results in temporary total disablement.`,
          `Educational support to the dependent children in the event of permanent total disability or
          death of the insured.`,
          `TLoan protection for a certain period in case the insured following an accident suffers
          permanent total disability or death.`,
          `Compensation for loss of employment due to total disablement following an accident.`,
          `Physical modification to the residence to facilitate better mobility following the disablement
          of the insured following an accident.`,
          `In the event of death of the insured, cost of transportation of the mortal remains to the
          place of residence of the diseased insured.`,
        ]
      },
      {
        componentType: "paragraph",
        content: `Since the coverage under this policy offers scope for customisation insurance companies do
        offer various customized covers. Hence it is important for the customer to
        examine the available
        covers in the market and choose the suitable ones.`
      }, 
      {
        componentType: "title",
        content: " Exclusions include the following: ",
      },
      {
        componentType: "list",
        content: [
          `Intentional self-injury, suicide, attempted suicide, injury under the influence of intoxicating
          liquor or drugs`,
          `Whilst engaging in adventure sports`,
          `Whilst committing any acts of breach of law with a criminal intent.`,
          `War risk`,
          `Nuclear risk`,
        ]
      },
      {
        componentType: "paragraph",
        content: `The above details present a broad picture of a typical personal accident insurance cover. Customer
        who intends to purchase personal accident
        insurance cover should examine the complete terms and
        conditions to understand implications of the wordings and make a suitable choice.`
      }, 
]