import { useState } from "react";
import { InputText } from "primereact/inputtext";
import CustomButton from "../../../components/CustomButton";
import { Link } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

function ContactForm() {
  
  const defaultValues = {
    name: "",
    email: "",
    number: "",
    subject: "",
    requirement: ""
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm(defaultValues);

  const handleFormSubmit = (data) => {
    const { name, email, number, subject, requirement } = data;

    // Format the message for WhatsApp
    const messageText = `Hello! I have a query regarding: *${subject}*.
    
    - Name: ${name}
    - Email: ${email}
    - Mobile: ${number}
    - Message: ${requirement}
    
    Please assist me with my query.`;

    // URL encode the message and open WhatsApp
    const whatsappUrl = `https://wa.me/918147829997?text=${encodeURIComponent(messageText)}`;
    window.open(whatsappUrl, "_blank");

    reset({
      name: "",
      email: "",
      number: "",
      subject: "",
      requirement: ""
    });
    
  };

  return (
    <div className=" ">
      
      <div className="p-field flex flex-column w-full mb-3">
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required." }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Name"
              className={{ "p-invalid": errors.name }}
            />
          )}
        />
        {errors.name && (
          <small className="p-error text-start">{errors.name.message}</small>
        )}
      </div>
      
      <div className="p-field flex flex-column w-full mb-3">
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required.",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address."
            }
          }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Email"
              className={{ "p-invalid": errors.email }}
            />
          )}
        />
        {errors.email && (
          <small className="p-error">{errors.email.message}</small>
        )}
      </div>
      
      <div className="p-field flex flex-column w-full mb-3">
        <Controller
          name="number"
          control={control}
          rules={{
            required: "Number is required.",
            pattern: {
              value: /^\d{10}$/,
              message: "Number must be 10 digits."
            }
          }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Number"
              className={{ "p-invalid": errors.number }}
            />
          )}
        />
        {errors.number && (
          <small className="p-error">{errors.number.message}</small>
        )}
      </div>
     
      <div className="p-field flex flex-column w-full mb-3">
        <Controller
          name="subject"
          control={control}
          rules={{ required: "Subject is required." }}
          render={({ field }) => (
            <InputText
              {...field}
              placeholder="Subject"
              className={{ "p-invalid": errors.subject }}
            />
          )}
        />
        {errors.email && (
          <small className="p-error">{errors.subject.message}</small>
        )}
      </div>
      
      <div className="p-field flex flex-column w-full mb-3">
        <Controller
          name="requirement"
          control={control}
          rules={{ required: "Requirement is required." }}
          render={({ field }) => (
            <InputTextarea
              {...field}
              placeholder="Requirement"
              rows={5}
              autoResize
              className={{ "p-invalid": errors.requirement }}
            />
          )}
        />
        {errors.requirement && (
          <small className="p-error">{errors.requirement.message}</small>
        )}
      </div>

      <div>
        <CustomButton label="Get started Today" className="w-full" onClick={handleSubmit(handleFormSubmit)} />
      </div>
    </div>
  );
}

export default ContactForm;
