
export const CommercialGeneralLiabilityInsuranceData = [
    {
        componentType: "title",
        content: "Commercial general",
        spantext: " liability insurance"
      },
      {
        componentType: "paragraph",
        content: `In the market we have public liability insurance and product liability insurance policies.
        Public liability which covers the liabilities arising out of damages to third party arising out of
        operation in the business premises, excludes liability arising out of use of the products.
        Product liability insurance covers liability arising out of damages to consumers, due to
        products produced or serviced by the insured but this cover excludes public liability.`
      },
      {
        componentType: "paragraph",
        content: `However Commercial General liability provides cover both for public liability and product
        liability faced by insured. Hence this cover is highly suitable for business establishments in
        manufacturing hospitality, healthcare, food processing, pharma industry etc.`
      },
      {
        componentType: "paragraph",
        content: `This policy covers not only the direct financial loss due to compensation payable arising out
        of injury / death / property damage but also covers the legal cost incurred to defend against
        the allegations of negligence against the insured.`
      },
      
]