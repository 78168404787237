export const TravelInsuranceData =[
    
    {
      componentType: "paragraph",
      content: `When travel insurance was initially introduced, it was primarily designed to cover medical
      emergencies when travelling abroad. Such policies were popularly known
      as “Overseas Mediclaim”
      policies. In time, this policy evolved to include many more covers to deal with various exigencies that
      travellers are exposed to during travel.
      However medical cover still remains the primary cover under
      travel insurance.`
    },
    {
      componentType: "paragraph",
      content: `During travel, especially overseas travel, travellers are exposed to many uncertainties which can
      result in financial losses. Travel insurance is designed protect
      the travellers from financial losses
      arising out of such exigencies`
    },
    {
      componentType: "paragraph",
      content: `People undertake travel for various purposes and to various countries. Hence the risks they are
      exposed to are very diverse. A student travelling for study purpose
      is exposed to risks which are
      different from risks a business traveller or tourist is expose to. A persontravelling to US has to deal
      with certain risks which could be
      different from risks faced while travelling to UAE or Singapore.
      Therefore,insurance companies have started segmenting travellers and customise the travel
      insurance plans. The market caters to Schengen visaholders, family travelling together, students
      travelling for education, senior citizens, corporate travellers
      travelling for business purpose,people
      travelling specifically for medical treatments etc.`
    },
    {
        componentType: "title",
        content: " Coverage: ",
      },
    {
      componentType: "paragraph",
      content: `The travel policies provide covers for various contingencies including the following:`
    },
    {
        componentType: "list",
        content: [
          `Medical expenditure due to any medical emergencies during travel`,
          `Medical assistance`,
          `Medical evacuation`,
          `Repatriation of mortal remains to India`,
          `Personal accident`,
          `Lost passport`,
          `Flight delays`,
          `Trip cancellation`,
          `Trip curtailment`,
          `Missed flight connection / Missed departure`,
          `Bounced bookings of hotels / airlines`,
          `Lost baggage`,
          `Delayed delivery of baggage`,
          `Home Insurance whilst the insured away travelling`,
          `Personal liability`,
        ]
      },
    {
      componentType: "paragraph",
      content: `Not all policies provide all the covers. Hence when choosing a travel insurance policy, one needs
      to examine covers required and covers available and choose the
      most suitable covers available.`
    },
    {
        componentType: "title",
        content: " Exclusions: ",
      },
    {
      componentType: "paragraph",
      content: `Each of the cover is subject to its own terms, conditions and exclusions. Also, policy is subject to
      general exclusions, terms and conditions that is uniformly
      applicable to all the covers.
      Awareness of such terms, conditions and exclusions is very important because facing the secontin gencies overseas can be very
      challenging.`
    },
   
  ];