import React from "react";
import "./style.css";

const CustomList = ({ listItems = [], className = "", style = {} }) => {
  return (
    <ul className={`custom-list ${className}`} style={style}>
      {listItems.map((item, index) => (
        <li key={index} className="list-item">
          * {item}
        </li>
      ))}
    </ul>
  );
};

export default CustomList;
