
export const ProfessionalIndemnityInsuranceData = [
    {
        componentType: "title",
        content: "Professional",
        spantext: " indemnity insurance"
      },
      {
        componentType: "paragraph",
        content: `Professional indemnity insurance covers professionals and business owners against the
        claims made on them, by their customers for their financial losses, due to omissions and
        mistakes. This policy caters to professionals like chartered accountants, architects, surveyors
        &amp; loss assessors, insurance brokers &amp; agents, doctors, advertising professionals, consultants
        etc. When they are rendering service to their clients they do so under certain service
        requirements and conditions. Any breach of requirements and conditions, resulting in
        financial loss to the customers gives right to such customers to seek compensation for the
        losses from such professionals.`
      },
      
]