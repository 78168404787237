import React from "react";
import classes from "./CustomTable.module.css"; // You can add a separate CSS file for table styling

const CustomTable = ({ headers, rows, className = "" }) => {
    return (
        <table className={`${classes.customTable} ${className}`}>
          <thead>
            <tr>
              {/* Render the first level of headers */}
              {headers?.map((header, index) => (
                <th colSpan={header?.subHeaders ? header?.subHeaders.length : 1} key={index} className={classes.tableHeader}>
                  {header?.title}
                </th>
              ))}
            </tr>
            <tr>
              {/* Render the sub-headers under each primary header */}
              {headers?.map((header, index) => (
                <>
                  {header?.subHeaders ? (
                    header?.subHeaders.map((subHeader, subIndex) => (
                      <th key={`${index}-${subIndex}`} className={classes.tableSubHeader}>
                        {subHeader}
                      </th>
                    ))
                  ) : (
                    <th key={index}></th> // Empty header cell for columns without sub-headers
                  )}
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex} className={classes.tableCell}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
};

export default CustomTable;
